import React, { Component } from 'react';
import PropTypes  from 'prop-types';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';

import styled from 'styled-components'
import { Modal, Segment, Button, Input, Popup, Icon, Accordion, Table, Divider, Message } from 'semantic-ui-react';

import { snakeToCamel } from 'helpers';
import { showNotification } from 'helpers/notification'
import { mapValues } from 'lodash'

import { TYPE_ICONS, TYPE_COLORS } from 'store/Integration';

import { CountsStore } from './Counts.js'
import { DATETIME_FORMAT } from 'helpers'
import { format } from 'helpers/date'
import moment from 'moment'
import subscribe from 'mixin/subscribe'



/**
 * Modal with 'start sync' button and progress meters to visualize
 * live statistics on the synchronization process.
 */
@observer
export class SyncModal extends subscribe(Component) {

  static propTypes = {
    integration: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    open: PropTypes.bool,
  }

  static defaultProps = {
    open: false,
  }

  @observable syncs = {};
  @observable typedIntegration = {};

  countsStore = new CountsStore();

  constructor(props) {
    super();

    const { integration } = props;

    this.type = integration.type;
    this.typedIntegration = integration[`${snakeToCamel(this.type)}Integration`];

    this.syncIntegration = this.syncIntegration.bind(this);
    this.parse_sync_finish = this.parse_sync_finish.bind(this);
    this.parse_sync_update = this.parse_sync_update.bind(this);
  }

  componentDidMount() {
    // Subscribe to Exact sync updates
    this.subscribe(
      { type: 'exact_sync_update', integration: '*', sync: '*' },
      action((data) => {
        this.parse_sync_update(data)
      })
    )

    // Subscribe to Exact sync finish notification (with final counts)
    this.subscribe(
      { type: 'exact_sync_result', integration: '*', sync: '*' },
      action((data) => this.parse_sync_finish(data))
    )

    // Subscribe to pending performances updates
    this.subscribe(
      { type: 'integration_pending_performances_update', integration: this.props.integration.id },
      action((data) => {
        this.parse_sync_pending_performances_update(data)
      })
    )

    // Subscribe to last performance updates
    this.subscribe(
      { type: 'integration_last_performance_update', integration_id: this.props.integration.id, sync_request: '*' },
      action((data) => {
        this.parse_sync_last_performance_update(data)
      })
    )

    // Subscribe to sync api status updates
    this.subscribe(
      { type: 'exact_api_status_update', integration: this.props.integration.id },
      action(() => {
        this.props.integration.fetch()
      })
    )

    this.subscribe(
      { type: 'exact_sync_service_status_update', integration: this.props.integration.id },
      action(() => {
        this.props.integration.fetch()
      }
    ))
  }

  syncIntegration() {
    // reset all existing counts
    this.countsStore = new CountsStore();

    this.props.integration.wrapPendingRequestCount(this.typedIntegration.wrapPendingRequestCount(
        this.typedIntegration.api.post(`${this.typedIntegration.url}sync/`, { action: 'all' })
        .then(({ sync }) => {
          this.syncs[this.typedIntegration.id] = sync;
        })
    ))
  }

  parse_sync_update({ data: { integration, sync, result } }) {
    const counts = result.counts;

    // See if we already have received some counts for this syncer
    const current_counts = this.countsStore.get(counts.syncer);
    if (current_counts === undefined) {
      // Create new counts model
      this.countsStore.add(counts);
    }

    // Update counts
    Object.assign(this.countsStore.get(counts.syncer), counts);
  }

  parse_sync_finish({ data: { integration, sync, result } }) {

    const handle = () => {
      if (this.syncs[integration] === sync) {
        delete this.syncs[integration]
        switch (result.code) {
          case 'success':
            // eslint-disable-next-line
            for (const [topic, topicCounts] of Object.entries(result.counts)) {
              // eslint-disable-next-line
              for (const [system, systemCounts] of Object.entries(topicCounts)) {
                // eslint-disable-next-line
                for (const [action, count] of Object.entries(systemCounts)) {
                  const notificationKey = topic + action + count
                  showNotification({ key: notificationKey,message: t(`${snakeToCamel(this.type)}Integration.overview.syncResult.success.count.${topic}.${system}.${action}`, { count }), dismissAfter: 5000 })
                }
              }
            }
            showNotification({ message: t(`${snakeToCamel(this.type)}Integration.overview.syncResult.success.notification`), dismissAfter: 5000 })
            break
          case 'auth_failure':
            const { exactIntegration } = this.store.models.find(({ exactIntegration }) => exactIntegration.id === integration)
            if (exactIntegration) {
              exactIntegration.active = false
            }
            showNotification({ type: 'error', message: t(`${snakeToCamel(this.type)}Integration.overview.syncResult.authFailure.notification`), dismissAfter: 5000 })
            break
          default:
            // noop
        }
      }
    }

    if (this.syncs[integration] === undefined) {
      setTimeout(handle, 100)
    } else {
      handle()
    }
  }

  parse_sync_pending_performances_update({ data }) {
    this.props.integration.fetch()

    // Update model
    // this.props.integration.pendingPerformances = data['pending_sync_requests']
  }

  // Sync any performance updates
  parse_sync_last_performance_update({ data: { last_performed_at, sync_request_id, sync_request_type } }) {
    const integration = this.props.integration

    // Update frontend model
    integration.lastPerformedAt = moment(last_performed_at)
    console.log(format(moment(last_performed_at), DATETIME_FORMAT))
  }

  @computed get isLoading() {
    return this.props.integration.isLoading || this.syncs[this.typedIntegration.id] !== undefined;
  }

  render() {
    // add the loading property from ourselves
    const trigger = React.cloneElement(
      this.props.trigger,
      { loading: this.isLoading }
    );

    const { type, name } = this.props.integration;

    return (
      <Modal
        trigger={trigger}
        closeOnDimmerClick={false}
        closeIcon
        data-test-batch-modal
      >
        <Modal.Header>
          <Popup style={{ left: '-10px' }}
            trigger={<Icon name={TYPE_ICONS[type]} size='small' style={{ color: TYPE_COLORS[type] }} />}
            content={t(`integration.field.type.value.${type}`)}
          />

          {name}
        </Modal.Header>

        <Modal.Content>
          <SyncCounts countsStore={this.countsStore} />
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            loading={this.isLoading}
            onClick={this.syncIntegration}
          >{t('integration.syncmodal.syncButton')}</Button>
        </Modal.Actions>
      </Modal>
    )
  }

}


/** Stacked progress bar meter for created, updated, pending counts. */
function ProgressBar(props) {
  const { bars, className } = props;

  const noRightBorderStyle = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
  const noLeftBorderStyle = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }

  function barStyle(percentage, color) {
    return {
      backgroundColor: `${color}`,
      width: `${percentage}%`,
    }
  }

  const total = bars.map((bar) => bar.value).reduce((a, b) => a + b, 0);

  const visibleBars = bars.filter((e) => e.value > 0)

  return (
    <div className={className}>
      <div className='ui progress' style={{ display: 'flex', margin: '0' }} >
        {
          visibleBars.map((e, i) => {
              let style = barStyle(e.value / total * 100, e.color);
              if (visibleBars.length > 1) {
                if (i === 0) {
                  style = Object.assign(style, noRightBorderStyle)
                } else if (i === visibleBars.length - 1) {
                  style = Object.assign(style, noLeftBorderStyle)
                } else {
                  style = Object.assign(style, noLeftBorderStyle, noRightBorderStyle)
                }
              }
              return (
                <div className='bar' style={style} key={i}>
                  <div className='progress' key={i}>{e.label}</div>
                </div>
              )
            })
        }
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  bars: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number
  })),
}



/** Accordion with foldable table of results per object type. */
@observer
export class SyncCounts extends React.Component {

  static propTypes = {
    countsStore: PropTypes.instanceOf(CountsStore).isRequired,
  }

  constructor(props) {
    super(props);

    this.getPanel = this.getPanel.bind(this);

    this.fold = this.fold.bind(this);
    this.fold_all = this.fold_all.bind(this);
    this.unfold_all = this.unfold_all.bind(this);
  }

  componentDidMount() {
    // Force states being updated.
    // ...otherwise, closing the modal and opening it again prevents
    // the user from folding, because of some mobx feason that
    // I don't know
    this.fold_all()
  }

  // to filter on syncer key
  @observable filter_key = '';

  // record the panels fold state
  @observable panel_states = {};

  @computed get panels() {
    const { countsStore } = this.props;

    return countsStore.length > 0 &&
      countsStore
        // filter on our filter input
        .filter((counts) => this.getTranslatedSyncerName(counts.syncer).toLowerCase()
          .indexOf(this.filter_key.toLowerCase()) >= 0)
        // only show the ones which have actual requested objects
        .filter((counts) => counts.requested > 0)
        .map(this.getPanel)
        // sort alphabetically
        .sort((a, b) => {
          if(a.key < b.key) { return -1; }
          if(a.key > b.key) { return 1; }
          return 0;
        });
  }

  /** Get the display name for the syncer. */
  getTranslatedSyncerName(syncer) {
    return t(`integration.syncers.${syncer}`)
  }

  getPanel(counts) {
    const SmallProgressBar = styled(ProgressBar)`
      min-width: 300px;
      display: block;
      float: right;
      position: relative;
      top: -2px;

      @media (max-width: 768px) {
        float: none;
        margin-top: 5px !important;
      }
    `

    // for now, just hide the progress bar if no objects were requested
    // const show_progress_bar = counts.requested > 0;

    let active = false;
    if (counts.syncer in this.panel_states) {
      active = this.panel_states[counts.syncer]
    } else {
      this.panel_states[counts.syncer] = false
    }

    const { created, updated, pending, from_db } = counts;

    const CountIcon = styled(Icon)`
      margin-right: 0 !important;
    `

    const bars = [
      { value: from_db, label: ( <> <CountIcon name='database' size='small' /> {from_db} </> ), color: '#339A71' },
      { value: updated, label: ( <> <CountIcon name='angle double right' size='small' /> {updated} </> ), color: '#21BC4C' },
      { value: created, label: ( <> <CountIcon name='plus' size='small' /> {created} </> ), color: '#17C590' },
      { value: pending, label: ( <> <CountIcon name='wait' size='small' /> {pending} </> ), color: '#DECDCD' },
    ]

    return (
      {
        key: counts.syncer,
        active: active,
        onTitleClick: () => this.fold(counts.syncer),
        title: [
              <span key={`${counts.syncer}_span`}>
                { this.getTranslatedSyncerName(counts.syncer) }
              </span>,
              <SmallProgressBar bars={bars} />
        ],
        content: {
          content: (
            <React.Fragment>
              <Table compact size='small'>
                <Table.Header>
                  <Table.Row>
                  {['requested', 'pending', 'from_db', 'from_inline', 'updated', 'created'].map((prop) => (
                    <Table.HeaderCell key={prop}>{t(`integration.syncmodal.${prop}`)}</Table.HeaderCell>
                  ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    {['requested', 'pending', 'from_db', 'from_inline', 'updated', 'created'].map((prop) => (
                        <Table.Cell key={prop}>{counts[prop]}</Table.Cell>
                    ))}
                  </Table.Row>
                </Table.Body>
              </Table>
              { Object.entries(counts.deps).length > 0 &&
                <Table definition compact size='small'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>{t('integration.syncmodal.dependencies')}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    { Object.entries(counts.deps).map(([key, value]) => (
                      <Table.Row key={key}>
                        <Table.Cell>{t(`integration.syncers.${key}`)}</Table.Cell>
                        <Table.Cell>{value}</Table.Cell>
                      </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              }
            </React.Fragment>
          )
        }
      }
    )
  }

  fold(syncer) {
    this.panel_states[syncer] = ! this.panel_states[syncer]
  }

  fold_all() {
    this.panel_states = mapValues(this.panel_states, () => false);
  }

  unfold_all() {
    this.panel_states = mapValues(this.panel_states, () => true);
  }

  render() {
    return (
      <>
        <Message warning
          header='Experimental statistics'
          content='Note that the shown statistics are still experimental at this stage. Even if objects were successfully imported, it may be that this is not reflected in the numbers.'
        />

        <Segment>

          <Input
            autoFocus
            placeholder='Search...'
            icon='search'
            value={this.filter_key}
            onChange={(e) => this.filter_key = e.target.value}
          />

          <Button.Group floated='right'>
            <Button icon onClick={this.unfold_all}>
              <Icon name='plus square outline' />
            </Button>
            <Button icon onClick={this.fold_all}>
              <Icon name='minus square outline' />
            </Button>
          </Button.Group>

        { this.panels && this.panels.length > 0 && (
          <>
            <Divider hidden />
            <Accordion  styled panels={this.panels} exclusive={false} fluid />
          </>
        )}

        </Segment>

      </>
    )
  }

}
