import ProductionLineEdit from '../screen/ProductionLine/Edit'
import ProgressOverviewScreen from '../screen/Progress/Overview';
import capacityFeatureRouter from 'feature/capacity/router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';
import { ModuleRepository } from '_iae/module/repository';
import { ViewStore } from '@code-yellow/spider';

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);


const UserOverview = Load(() => import('../screen/User/Overview'))
const UserEdit = Load(() => import('../screen/User/Edit'))
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'))
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'))
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'))
const ProjectsOverview = Load(() => import('../screen/Projects/Overview'));
const NestTypeOverview = Load(() => import('../screen/NestType/Overview'));
const NestOverview = Load(() => import('../screen/Nest/Overview'));

const OperatorOverview = Load(() => import('../screen/Operator/Overview'))
const OperatorEdit = Load(() => import('../screen/Operator/Edit'))
const OperatorStart = Load(() => import('../screen/Operator/Start'))
const OperatorHome = Load(() => import('../screen/Operator/Home'))
const OperatorAvailability = Load(() => import('../screen/Operator/Availability'))
const OperatorLeaveRequestsHistory = Load(() => import('../screen/Operator/LeaveRequestsHistory'))

const LeaveCalendarOverview = Load(() => import('../screen/LeaveCalendar/Overview'))
const LeaveCalendarEdit = Load(() => import('../screen/LeaveCalendar/Edit'))

const LeaveSlotOverview = Load(() => import('../screen/LeaveSlot/Overview'))

const ArticleTypeOverview = Load(() => import('../screen/ArticleType/Overview'))
const ArticleTypeEdit = Load(() => import('../screen/ArticleType/Edit'))

const BatchTypeOverview = Load(() => import('../screen/BatchType/Overview'))
const BatchTypeEdit = Load(() => import('../screen/BatchType/Edit'))

const TransferOverview = Load(() => import('../screen/BatchType/TransferOverview'))
const TransferEdit = Load(() => import('../screen/BatchType/TransferEdit'))

const DataSourceOverview = Load(() => import('../screen/DataSource/Overview'))
const DataSourceEdit = Load(() => import('../screen/DataSource/Edit'))

const LoadCarrierOverview = Load(() => import('../screen/LoadCarrier/Overview'))
const LoadCarrierEdit = Load(() => import('../screen/LoadCarrier/Edit'))

const ProductionLineOverview = Load(() => import('../screen/ProductionLine/Overview'))

const CapabilityOverview = Load(() => import('../screen/Capability/Overview'))
const CapabilityEdit = Load(() => import('../screen/Capability/Edit'))

const PlannerOverview = Load(() => import('../screen/Planner/Overview'))

const ProductionRequestEdit = Load(() => import('../screen/ProductionRequest/Edit'))
const ProductionRequestOverview = Load(() => import('../screen/ProductionRequest/Overview'))


const OrderHistoryOverview = Load(() => import('../screen/ProductionRequest/OrderHistory/Overview'))

const BatchOverview = Load(() => import('../screen/Batch/Overview'))

const WorkStationStart = Load(() => import('../screen/WorkStation/Start'))
const ProductionRequestProductionPerform = Load(() => import('../screen/ProductionRequest/ProductionPerform'))

const ExactOk = Load(() => import('../screen/Exact/Ok'))
const ExactError = Load(() => import('../screen/Exact/Error'))

const Unit4Ok = Load(() => import('../screen/Unit4/Ok'))
const Unit4Error = Load(() => import('../screen/Unit4/Error'))

const LoginForgot = Load(() => import('../screen/LoginForgot'))
const Activate = Load(() => import('../screen/Activate'))
const ResetPassword = Load(() => import('../screen/ResetPassword'))
const Profile = Load(() => import('../screen/Profile'))
const Changelog = Load(() => import('../screen/Changelog'))

const NotFound = Load(() => import('../container/NotFound'))

const PrinterSetup = Load(() => import('../screen/PrinterSetup'))

const WarehouseOverview = Load(() => import('screen/Logistics/Warehouse'))
const WarehouseEdit = Load(() => import('screen/Logistics/WarehouseEdit'))

const StockOverview = Load(() => import('screen/Logistics/Stock'))
const InboundOverview = Load(() => import('screen/Logistics/Inbound'))
const WarehouseTransferOverview = Load(() => import('screen/Logistics/WarehouseTransfer/Overview'))

const IntegrationOverview = Load(() => import('screen/Integration/Overview'))
const SyncErrorOverview = Load(() => import('screen/Integration/SyncError/Overview'))
const PerformSyncOverview = Load(() => import('screen/Integration/PerformSync/Overview'))
const RadanIntegrationInboxOverview = Load(() => import('screen/Integration/RadanIntegration/InboxOverview'))


const SyncRequestOverview = Load(() => import('screen/SyncRequest/Overview'))

const WorkStationSessionOverview = Load(() => import('../screen/WorkStationSession/Overview'))

const FactoryOverview = Load(() => import('../screen/Factory/Overview'))
const SalesOrderOverview = Load(() => import('../screen/SalesOrder/Overview'))
const SalesOrderEdit = Load(() => import('../screen/SalesOrder/Edit'))

const NavisionSyncRequestOverview = Load(() => import('../screen/NavisionSyncRequest/Overview'))

const MetafieldOverview = Load(() => import('../screen/Metafield/Overview'))

const ClassificationOverview = Load(() => import('screen/Classification/Overview'))
const ClassificationEdit = Load(() => import('screen/Classification/Edit'))

const ResourceAllocationOverview = Load(() => import('screen/ResourceAllocation/Overview'));


export default class Router extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(ViewStore).isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
  };

  // react-router is a bit too verbose so I made a shorthand
  route = (Screen, autoRefreshOnParams = false) => {
    const { moduleRepository } = this.props;

    return rProps => (
      <Screen
        {...rProps}
        key={autoRefreshOnParams ? JSON.stringify(rProps.match.params) : ''}
        viewStore={this.props.store}
        moduleRepository={moduleRepository}
      />
    );
  };

  redirectFromHome = () => {
    const { store } = this.props
    const { currentUser } = store
    let uri = ''
    if (store.isWorkStation) {
      uri = '/operations/production-request/overview'
    } else if (store.isLoggedInAsOperator) {
      uri = '/operator'
    } else if (currentUser.isSuperuser) {
      uri = '/personnel/user/overview'
    } else {
      uri = '/operations/production-request/overview'
    }
    return <Redirect to={uri}/>
  }


  render() {
    const { moduleRepository } = this.props;
    return (
      <Switch>
        <Route path="/" exact render={this.redirectFromHome}/>
        <Route path="/operator/login" render={this.route(OperatorStart)} />
        <Route path="/operator/availability" render={this.route(OperatorAvailability)} />
        <Route path="/operator/leave-requests-history" render={this.route(OperatorLeaveRequestsHistory)} />
        <Route path="/operator" render={this.route(OperatorHome)} />

        <Route path="/personnel/user/overview" render={this.route(UserOverview)} />
        <Route path="/personnel/user/:id/edit/:tab?" render={this.route(UserEdit)} />
        <Route path="/personnel/user/add/:tab?" render={this.route(UserEdit)} />
        <Route path="/personnel/user/email-log/overview" render={this.route(EmailLogOverview)} />

        <Route path="/personnel/operator/overview" render={this.route(OperatorOverview)} />
        <Route path="/personnel/operator/:id/edit/:tab?" render={this.route(OperatorEdit)} />
        <Route path="/personnel/operator/add/:tab?" render={this.route(OperatorEdit)} />

        <Route path="/personnel/leave-calendar/overview" render={this.route(LeaveCalendarOverview)} />
        <Route path="/personnel/leave-calendar/:id/edit" render={this.route(LeaveCalendarEdit)} />
        <Route path="/personnel/leave-calendar/add" render={this.route(LeaveCalendarEdit)} />

        <Route path="/personnel/leave-slot/overview" render={this.route(LeaveSlotOverview)} />
        <Route path="/personnel/leave-slot/:id/view" render={this.route(LeaveSlotOverview)} />

        <Route path="/personnel/capability/overview" render={this.route(CapabilityOverview)} />
        <Route path="/personnel/capability/:id/edit" render={this.route(CapabilityEdit)} />
        <Route path="/personnel/capability/add" render={this.route(CapabilityEdit)} />

        <Route path="/assets/article-type/overview" render={this.route(ArticleTypeOverview)} />
        <Route path="/assets/article-type/:id/edit/:view?" render={this.route(ArticleTypeEdit)} />

        <Route
          path="/assets/article-type/:id/batch-type/:batchTypeId/edit/:view?"
          render={this.route(ArticleTypeEdit)}
        />
        <Route
          path="/assets/article-type/:id/batch-type/:batchTypeId/version/:version/edit/:view?"
          render={this.route(ArticleTypeEdit)}
        />

        <Route path="/assets/article-type/add/:view?" render={this.route(ArticleTypeEdit)} />

        <Route path="/assets/process/overview" render={this.route(BatchTypeOverview)} />
        <Route path="/assets/process/add/:view?" render={this.route(BatchTypeEdit)} />
        <Route path="/assets/process/:id/edit/:view?" render={this.route(BatchTypeEdit)} />
        <Route path="/assets/process/:id/version/:version/edit/:view?" render={this.route(BatchTypeEdit)} />

        <Route path="/assets/transfer-process/overview" render={this.route(TransferOverview)} />
        <Route path="/assets/transfer-process/add/:view?" render={this.route(TransferEdit)} />
        <Route path="/assets/transfer-process/:id/edit/:view?" render={this.route(TransferEdit)} />
        <Route path="/assets/transfer-process/:id/version/:version/edit/:view?" render={this.route(TransferEdit)} />

        <Route path="/assets/data-source/overview" render={this.route(DataSourceOverview)} />
        <Route path="/assets/data-source/:id/edit" render={this.route(DataSourceEdit)} />

        <Route path="/assets/production-line/overview" render={this.route(ProductionLineOverview)} />
        <Route
          path="/assets/production-line/:id/version/:version/edit/:view?"
          render={this.route(ProductionLineEdit)}
        />
        <Route path="/assets/production-line/:id/edit/:view?" render={this.route(ProductionLineEdit)} />
        <Route path="/assets/production-line/add/:view?" render={this.route(ProductionLineEdit)} />

        <Route path="/assets/load-carrier/overview" render={this.route(LoadCarrierOverview)} />
        <Route path="/assets/load-carrier/:id/edit" render={this.route(LoadCarrierEdit)} />
        <Route path="/assets/load-carrier/add" render={this.route(LoadCarrierEdit)} />

        <Route path="/planning/production-request/planner/:period?" render={this.route(PlannerOverview)} />

        <Route path="/planning/production-request/overview" render={this.route(ProductionRequestOverview)} />
        <Route path="/planning/production-request/add" render={this.route(ProductionRequestEdit)} />
        <Route path="/planning/production-request/:id/edit" render={this.route(ProductionRequestEdit)} />

        <Route path="/production/login" render={this.route(WorkStationStart)} />
        <Route path="/operations/batch/overview" render={this.route(BatchOverview)} />
        <Route path="/operations/production-request/overview/:order" render={this.route(ProgressOverviewScreen)} />
        <Route path="/operations/production-request/overview" render={this.route(ProgressOverviewScreen, true )} />
        <Route path="/operations/order-history/overview" render={this.route(OrderHistoryOverview )} />
        <Route
          path="/operations/production-request/:id/perform/:workstation?"
          render={this.route(ProductionRequestProductionPerform)}
        />
        <Route path="/operations/perform/:workstation/overview"
         render={this.route(ProgressOverviewScreen, true)}
         />
        <Route path="/assets/work-station-session/overview" render={this.route(WorkStationSessionOverview)} />

        <Route path="/warehouse/stock/articletypewarehouse/:articletypewarehouse" render={this.route(StockOverview)} />
        <Route path="/warehouse/stock" render={this.route(StockOverview)} />
        <Route path="/assets/warehouse/:id/edit" render={this.route(WarehouseEdit)} />
        <Route path="/assets/warehouse" render={this.route(WarehouseOverview)} />
        <Route path="/warehouse/inbound/order/:order/shipment/:shipment" render={this.route(InboundOverview)} />
        <Route path="/warehouse/inbound/order/:order" render={this.route(InboundOverview)} />
        <Route path="/warehouse/inbound" render={this.route(InboundOverview)} />

        <Route path="/warehouse/warehouse-transfer" render={this.route(WarehouseTransferOverview)} />

        <Route path="/exact/ok" render={this.route(ExactOk)} />
        <Route path="/exact/error/:error" render={this.route(ExactError)} />

        <Route path="/unit4/ok" render={this.route(Unit4Ok)} />
        <Route path="/unit4/error/:error" render={this.route(Unit4Error)} />

        <Route path="/profile/:tab?" render={this.route(Profile)} />
        <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)} />
        <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

        <Route path="/assets/projects/overview" render={this.route(ProjectsOverview)} />

        <Route path="/changelog" render={this.route(Changelog)} />
        <Route path="/login/forgot" render={this.route(LoginForgot)} />
        <Route path="/user/:id/activate/:token" render={this.route(Activate)} />
        <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />
        <Route path="/printer-setup" render={this.route(PrinterSetup)} />

        <Route path="/assets/factory/overview" render={this.route(FactoryOverview)} />

        <Route path="/assets/integration/overview" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/exact/ok" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/exact/error/:error" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/unit4/ok" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/unit4/error/:error" render={this.route(IntegrationOverview)} />
        <Route path="/assets/integration/:integration/sync-error/overview" render={this.route(SyncErrorOverview)} />
        <Route path="/assets/integration/:integration/perform-sync/overview" render={this.route(PerformSyncOverview)} />
        <Route path="/assets/integration/:integration/radan-integration-inbox/overview" render={this.route(RadanIntegrationInboxOverview)} />

        <Route path="/assets/sync-request/overview" render={this.route(SyncRequestOverview)} />
        <Route path="/demand/sales-order/overview" render={this.route(SalesOrderOverview)} />
        <Route path="/demand/sales-order/:id/edit" render={this.route(SalesOrderEdit)} />
        <Route path="/navisionsyncoverview" render={this.route(NavisionSyncRequestOverview)} />

        <Route path="/assets/metafield/overview" render={this.route(MetafieldOverview)} />

        <Route path="/assets/classification/overview" render={this.route(ClassificationOverview)} />
        <Route path="/assets/classification/:id/edit" render={this.route(ClassificationEdit)} />

        <Route path="/assets/nest-type/overview" render={this.route(NestTypeOverview)} />
        <Route path="/planning/nest/overview" render={this.route(NestOverview)} />

        <Route path="/planning/resource-allocations" render={this.route(ResourceAllocationOverview)} />

        {moduleRepository.getRoutes().map(moduleRoute => {
                    return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)}/>
                })}

      {capacityFeatureRouter(this.route)}
        {moduleRepository.getRoutes().map(moduleRoute => {
          return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)}/>
        })}

        <Route render={this.route(NotFound)} />
      </Switch>
    )
  }
}
